import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { openPopupWidget } from "react-calendly"

import HeaderFlat from "../components/HeaderFlat"
import BookStrategyBtnPrimary from "../components/BookStrategyBtnPrimary"
import FooterGlobal from "../components/FooterGlobal"
import SEO from "../components/seo"
import Layout from "../components/layout"
import MainNav from "../components/MainNav"

import { FaPlus } from "react-icons/fa"
import { FaMinus } from "react-icons/fa"

export const getData = graphql`
  {
    wpgraphql_greatweb {
      pageBy(uri: "seo-philippines") {
        pageSiteMetaTitle_acf {
          sitemetatitle
        }
        pageSiteMetaDescription_acf {
          siteMetaDescription
        }
      }
      homePageBy: pageBy(uri: "home") {
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              countdownData
              companyAddress
              companyContactNumber
              companyOverview
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
          }
        }
      }
    }
  }
`

const SEOPhilippines = ({ data }) => {
  const {
    wpgraphql_greatweb: {
      pageBy: {
        pageSiteMetaTitle_acf: { sitemetatitle },
        pageSiteMetaDescription_acf: { siteMetaDescription },
      },
      homePageBy: {
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              countdownData,
              companyAddress,
              companyContactNumber,
              companyOverview,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
          },
        },
      },
    },
  } = data

  const [show, setShow] = useState(0)

  const handleAccordion = id => {
    if (show === id) {
      setShow(0)
    } else {
      setShow(id)
    }
  }

  const handleCalendly = () => {
    const url = "https://calendly.com/rayjenscode/15"
    openPopupWidget({ url })
  }

  return (
    <>
      <Layout
        announcement={!announcement ? "" : announcement}
        countdownData={!countdownData ? "" : countdownData}
      >
        <SEO title={sitemetatitle} description={siteMetaDescription} />
        <MainNav active="services" />
        <HeaderFlat
        // siteTitle={data.site.siteMetadata?.title || `GreatWeb`}

        // background={heroBG}
        // contact={contactNumber}
        />

        <section className="seo-philippines">
          <div className="seo-philippines__header container">
            <div className="seo-philippines__header__left">
              <p className="seo-philippines__header__left--note">
                SEO IN PHILIPPINES
              </p>
              <h1 className="seo-philippines__header__left--heading">
                Guaranteed Google Rankings To Rise Above Your Competitors
              </h1>
              <h2 className="seo-philippines__header__left--subheading">
                Our no-risk SEO offer will drastically increase your Google
                ranking and boost sales or we’ll work for{" "}
                <span className="seo-philippines__header__left--span">
                  FREE!
                </span>
              </h2>

              <button
                className="mt-3 btn btn-primary btn-large text-normal"
                onClick={handleCalendly}
              >
                Speak with me about <span className="text-bold">SEO</span>
              </button>
            </div>
          </div>

          <div className="seo-philippines__pitch">
            <h2 className="seo-philippines__pitch--text">
              Reach local prospects{" "}
              <span className="seo-philippines__pitch--text--span">
                searching to buy
              </span>{" "}
              your services with the{" "}
              <span className="seo-philippines__pitch--text--span">
                most trusted SEO company in the Philippines
              </span>
              .
            </h2>
          </div>
          <div className="seo-philippines__cover container">
            <h2 className="seo-philippines__cover--heading">
              Guaranteed Results
            </h2>

            <p className="seo-philippines__cover--subheading">
              Great Web is an SEO company in the Philippines that guarantees SEO
              results and Google rankings.
            </p>

            <p className="seo-philippines__cover--subheading">
              No, SEO is not an exact science. Anyone that tells you so is
              lying.
            </p>
            <p className="seo-philippines__cover--subheading">
              However, we know what works to get your site ranked and if you
              don't see an increase in sales, we will work for FREE until you
              do.
            </p>
            <p className="seo-philippines__cover--subheading">
              {" "}
              Don't believe us? Check out some recent client results...
            </p>
            <div className="seo-philippines__cover--imgWrapper">
              <img
                src={require("../images/seo-result-graph.png")}
                alt="Great Web SEO result"
                className="seo-philippines__cover--img"
              />
            </div>

            <h2 className="seo-philippines__cover--heading mt-5">
              We Make You More Money...
            </h2>
            <p className="seo-philippines__cover--subheading">
              Most SEO agencies have it all wrong! They focus on rankings, not
              revenue. Rankings and traffic are great, but if those visitors
              don’t convert into actual dollars, it doesn’t matter. All we care
              about is how much revenue and profit we can drive to your
              business. To do this, we must create a complete strategy by
              ranking for high-value keywords and preparing your site to convert
              that warm traffic into paying customers/clients.
            </p>

            <h2 className="seo-philippines__cover--heading mt-5">
              Monthly Video Reports
            </h2>
            <p className="seo-philippines__cover--subheading">
              You're busy and you shouldn't have to spend your valuable time
              deciphering complicated SEO reporting emails.
            </p>
            <p className="seo-philippines__cover--subheading">
              I cut out the fluff with video SEO reporting that gets to the
              point about what is going on with your online ranking. Now you can
              stay up to date on your SEO progress in less than 3 minutes/month.
            </p>

            <div className="seo-philippines__cover--imgWrapper">
              <img
                src={require("../images/seo-report-video.png")}
                alt="Great Web SEO Report Video"
                className="seo-philippines__cover--img"
              />
            </div>

            <BookStrategyBtnPrimary />
          </div>

          <div className="seo-philippines__faq container">
            <h3 className="seo-philippines__faq--heading">
              Frequently Asked Questions
            </h3>
            <ul className="seo-philippines__faq--list">
              <li className="seo-philippines__faq--item">
                <h4
                  className="seo-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(1)}
                >
                  <div>
                    {show === 1 ? (
                      <FaMinus className="seo-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="seo-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="seo-philippines__faq--item--question--span">
                    What kind of results can I expect?
                  </span>
                </h4>
                {/* {show === 1 && ( */}
                <div
                  className={`${
                    show === 1
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="seo-philippines__faq--item--description">
                    It is not unusually for us to see your web traffic and
                    conversions double within the first 6-9 months. That being
                    said, some businesses are more primed to see massive web
                    growth. If you are just starting out with a new website, the
                    growth may be slower.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="seo-philippines__faq--item">
                <h4
                  className="seo-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(2)}
                >
                  <div>
                    {show === 2 ? (
                      <FaMinus className="seo-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="seo-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="seo-philippines__faq--item--question--span">
                    Do you guarantee results?
                  </span>
                </h4>
                {/* {show === 2 && ( */}
                <div
                  className={`${
                    show === 2
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="seo-philippines__faq--item--description">
                    While SEO is not an exact art,{" "}
                    <span className="seo-philippines__faq--item--description--span">
                      I absolutely guarantee results.
                    </span>
                    Much of ranking your site on Google depends on the amount of
                    competition, quantity of search queries, and overall quality
                    of your business. While every situation is different, I
                    guarantee a significant and measurable increase in ranking
                    and website traffic or I work for free.
                  </p>
                  <p className="seo-philippines__faq--item--description">
                    *If you are a new business or new to the web, results will
                    take a bit longer. Rest assured they will come and will
                    generate consistent revenue for your business.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="seo-philippines__faq--item">
                <h4
                  className="seo-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(3)}
                >
                  <div>
                    {show === 3 ? (
                      <FaMinus className="seo-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="seo-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="seo-philippines__faq--item--question--span">
                    {" "}
                    How long does it take to start seeing progress?
                  </span>
                </h4>
                {/* {show === 3 && ( */}
                <div
                  className={`${
                    show === 3
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="seo-philippines__faq--item--description">
                    As mentioned before, SEO is not an exact art. As a Search
                    Engine Optimizer, I do everything possible to get your site
                    in the good graces of Google. That being said, Google
                    doesn't let you cheat your way to a higher ranking. Any one
                    that promises you "Rank #1 in 30 days or less" just isn't
                    being honest or realistic.
                  </p>
                  <p className="seo-philippines__faq--item--description">
                    That being said, you can typically expect to see a{" "}
                    <span className="seo-philippines__faq--item--description--span">
                      small increase in web traffic in the first 30-60 days.
                    </span>{" "}
                    After that, the growth becomes far more significant. After 6
                    months, most business see a pretty radical change in their
                    Google ranking and amount of calls and inquiries from the
                    internet.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="seo-philippines__faq--item">
                <h4
                  className="seo-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(4)}
                >
                  <div>
                    {show === 4 ? (
                      <FaMinus className="seo-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="seo-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="seo-philippines__faq--item--question--span">
                    How long do I need to do SEO?
                  </span>
                </h4>
                {/* {show === 4 && ( */}
                <div
                  className={`${
                    show === 4
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="seo-philippines__faq--item--description">
                    SEO is not a one time deal. It certainly requires upkeep to
                    maintain a solid ranking and high volume of traffic. While
                    the intensity and budget with which we approach it may vary,
                    some sort of SEO work will always be necessary.
                  </p>
                  <p className="seo-philippines__faq--item--description">
                    While this seems like a valid concern at the start, once I
                    start generating new customers for a business, they always
                    prefer to ramp things up rather than slow them down or quit.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="seo-philippines__faq--item">
                <h4
                  className="seo-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(5)}
                >
                  <div>
                    {show === 5 ? (
                      <FaMinus className="seo-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="seo-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="seo-philippines__faq--item--question--span">
                    What do I need to do on my end?
                  </span>
                </h4>
                {/* {show === 5 && ( */}
                <div
                  className={`${
                    show === 5
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="seo-philippines__faq--item--description">
                    As an SEO consultant, me and my team will handle almost
                    everything on our end. What we do need help with from you is
                    understanding your business. You know your business (and
                    customers) better than anyone so it will be important to
                    stay in contact as we launch and manage your campaign.
                  </p>
                  <p className="seo-philippines__faq--item--description">
                    Occasionally, I will need assistance with new
                    content/articles for your site. If this is the case, I will
                    consult you on how to assist in this.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="seo-philippines__faq--item">
                <h4
                  className="seo-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(6)}
                >
                  <div>
                    {show === 6 ? (
                      <FaMinus className="seo-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="seo-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="seo-philippines__faq--item--question--span">
                    Will you be better than other SEO companies I have paid for?
                  </span>
                </h4>
                {/* {show === 6 && ( */}
                <div
                  className={`${
                    show === 6
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="seo-philippines__faq--item--description">
                    All SEO companies are not created equal. There are some
                    fantastic companies in the philippines that are wizards at
                    SEO and some that don't seem to know what they are doing. I
                    am confident in getting results for your business just as I
                    have for other businesses. While I won't ignorantly claim to
                    be the best, I can guarantee you that I will{" "}
                    <span className="seo-philippines__faq--item--description--span">
                      work harder
                    </span>{" "}
                    and be more{" "}
                    <span className="seo-philippines__faq--item--description--span">
                      transparent
                    </span>{" "}
                    than any other SEO out there.
                  </p>
                </div>
                {/* )} */}
              </li>
            </ul>
            <div className="seo-philippines__ready">
              <h4 className="seo-philippines__ready--heading">
                Ready to Get Started?
              </h4>
              <p className="seo-philippines__ready--subheading">
                If you want to learn more about how I can help grow your
                business, click below to schedule a free growth call. If you are
                ready to start right away,{" "}
                <Link className="seo-philippines__ready--link" to="/contact">
                  click here to contact me.
                </Link>
              </p>
              {/* 
              <button
                className="mt-3 btn btn-primary btn-large text-normal"
                onClick={handleCalendly}
              >
                Schedule Call Now - It's Free
              </button> */}
              <BookStrategyBtnPrimary />
            </div>
          </div>
        </section>

        <FooterGlobal
          companyAddress={companyAddress}
          companyContactNumber={companyContactNumber}
          companyOverview={companyOverview}
          facebookLink={facebookLink}
          linkedinLink={linkedinLink}
          supportEmail={supportEmail}
          twiiterLink={twiiterLink}
        />
      </Layout>
    </>
  )
}

export default SEOPhilippines
